<template>
  <div class="app-container">
    <div class="banner"></div>
    <div class="case-container flex">
      <div
        class="case-item-wrapper flex"
        v-for="item in cases"
        :key="item.title"
      >
        <div class="case-item">
          <div class="title">{{ item.title }}</div>
          <div class="addr">{{ item.addr }}</div>
          <img :src="item.img" alt="" />
          <div class="desc">{{ item.desc }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cases: [
        {
          title: "天隆科技智慧工厂",
          addr: "地址：陕西省西安市未央区",
          img: require("../../assets/customerCase/TL.png"),
          desc: "天隆科技新址位于西安市未央区，全园区分6栋楼建设，楼宇主要功能包括试剂生产区、仪器生产组装、仪器研发、试剂研发、PCR实验室培训、展览、参观为一体。系统实现了能耗监控、环境监控、人员定位、视频监控、访客管理等功能。大屏宣传、楼层监控、房间管理等多种可视化展示。",
        },
        {
          title: "远征实验室",
          addr: "地址：陕西省西安市未央区草滩十路",
          img: require("../../assets/customerCase/TL.png"),
          desc: "远程实验室位于西安市草滩十路，主要业务为试剂生产、人员办公等。系统实现了环境监控、洁净度管理、人员管理、照明控制、空调控制等功能。",
        },
        {
          title: "安奈苏州实验室",
          addr: "地址：江苏省苏州市工业园区",
          img: require("../../assets/customerCase/TL.png"),
          desc: "安奈生物科技（苏州）有限公司位于苏州市工业园区，主营业务为生物医疗试剂及设备生产。系统实现了环境监控、报警管理、考勤管理、实验记录、资产管理、视频监控、能耗管理等功能。",
        },
        {
          title: "正邦集团",
          addr: "地址：江西省南昌市",
          img: require("../../assets/customerCase/ZB.png"),
          desc: "正邦集团位于江西省南昌市，主营业务为养殖及上下游行业。系统实现了从采样送检、结果分析、实验室管理等全链路的智能化管理。",
        },
        {
          title: "天博医学检验中心",
          addr: "地址：陕西省西安市未央区",
          img: require("../../assets/customerCase/TB.png"),
          desc: "天博医学检验中心位于西安市未央区，主营业务为病毒检测，如医院、体检中心等样本的检验。系统实现了到实验室从收样、检验结果、客户管理、生成报告等功能。",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@function vw($px) {
  @return ($px / 1920) * 100vw;
}

.banner {
  height: vw(516);
  background-color: #04132f;
}

.case-container {
  padding: 0 vw(370);
  margin-top: vw(40);
  margin-bottom: vw(100);
  flex-wrap: wrap;
  .case-item-wrapper {
    width: 50%;
    margin-top: vw(40);

    .case-item {
      height: vw(499);
      width: vw(570);
      //   padding: vw(40) vw(63) vw(56) vw(62);
      // padding-top: vw(40);
      box-shadow: 0px 0px 10px 0px rgba(58, 54, 54, 0.2);
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        margin-top: vw(40);
        margin-bottom: vw(14);
        font-size: vw(18);
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      .addr {
        font-size: vw(14);
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #777777;
      }
      img {
        width: vw(330);
        height: vw(180);
        margin: vw(29) 0;
      }
      .desc {
        font-size: vw(14);
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: vw(26);
        padding: 0 vw(62);
      }
    }

    &:nth-child(odd) {
      justify-content: flex-start;
    }
    &:nth-child(even) {
      justify-content: flex-end;
    }
  }
}
</style>